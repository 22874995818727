import logo from './logo.svg';
import './App.css'
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import PlaceOrder from "./components/PlaceOrder";
import OrderList from "./components/OrderList";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ManagerDashboard from "./components/ManagerDashboard";


function App() {
  const [user, setUser] = useState(null); // Store the logged-in user

  const ProtectedRoute = ({ role, children }) => {
    if (!user) return <Navigate to="/login" />;
    if (role && user.role !== role) return <Navigate to="/login" />;
    return children;
  };

  const element = document.getElementById("error-message");
console.log(element); // Check if it's null

  return (
    <Router>
    <div>
    {!user ? (
          <Routes>
            <Route path="/login" element={<Login onLogin={(loggedInUser) => setUser(loggedInUser)} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        ) : (
        <>
          <h1 className="text-center text-2xl font-bold mt-4">
            Welcome, {user.displayName || user.email} ({user.role})
          </h1>
          <Routes>
            <Route path="/place-order" element={
              <ProtectedRoute role="Staff">
                <PlaceOrder user={user} />
              </ProtectedRoute>
            } />
            <Route path="/manager" element={
              <ProtectedRoute role="Manager">
                <SignUp />
                <ManagerDashboard />
              </ProtectedRoute>
            } />
          </Routes>
        </>
      )}
    </div>
  </Router>
  );
}

export default App;
