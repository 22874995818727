import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, query, where, onSnapshot, getDocs } from "firebase/firestore";

const ManagerDashboard = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [selectedDate, setSelectedDate] = useState(new Date()); // Selected date

  // Fetch orders based on the selected date
  useEffect(() => {
    const fetchOrders = async () => {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      const orderQuery = query(
        collection(db, "orders"),
        where("closedAt", ">=", startOfDay),
        where("closedAt", "<=", endOfDay)
      );

      const unsubscribe = onSnapshot(orderQuery, (snapshot) => {
        const fetchedOrders = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(fetchedOrders);
      });

      return () => unsubscribe(); // Cleanup on unmount
    };

    fetchOrders();
  }, [selectedDate]);

  // Format date to YYYY-MM-DD for input
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const calculateDailyMetrics = async (date) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
  
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
  
    const orderQuery = query(
      collection(db, "orders"),
      where("closedAt", ">=", startOfDay),
      where("closedAt", "<=", endOfDay)
    );
  
    const snapshot = await getDocs(orderQuery);
    const orders = snapshot.docs.map((doc) => doc.data());
  
    const totalOrders = orders.length;
    const totalRevenue = orders.reduce((sum, order) => sum + (order.quantity * order.price || 0), 0);
  
    console.log(`Total Orders: ${totalOrders}, Total Revenue: ${totalRevenue}`);
  };  

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-100 shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Manager Dashboard</h2>

      {/* Date Filter */}
      <div className="mb-4">
        <label className="block text-lg font-semibold mb-2">Select Date:</label>
        <input
          type="date"
          value={formatDate(selectedDate)}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          className="p-2 border rounded w-full"
        />
      </div>

      {/* Order History Table */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-2">Order History</h3>
        {orders.length > 0 ? (
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <th className="text-left p-2">Product</th>
                <th className="text-left p-2">Quantity</th>
                <th className="text-left p-2">Staff</th>
                <th className="text-left p-2">Status</th>
                <th className="text-left p-2">Closed At</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id} className="border-b">
                  <td className="p-2">{order.productName}</td>
                  <td className="p-2">{order.quantity}</td>
                  <td className="p-2">{order.staffName}</td>
                  <td className="p-2">{order.status}</td>
                  <td className="p-2">
                    {order.closedAt?.toDate().toLocaleString() || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No orders found for this date.</p>
        )}
      </div>
    </div>
  );
};

export default ManagerDashboard;
