import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth"; // Add this import
import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

const PlaceOrder = () => {
  const [products, setProducts] = useState([]); // Store product list
  const [productName, setProductName] = useState(""); // Selected product
  const [quantity, setQuantity] = useState(1);
  const [staffName, setStaffName] = useState("");

  // Fetch products from Firestore
  useEffect(() => {
    const auth = getAuth();
  const user = auth.currentUser; // Get the currently logged-in user
  if (user) {
    setStaffName(user.displayName || user.email); // Use displayName or fallback to email
  }

    const fetchProducts = async () => {
      const productCollection = collection(db, "products");
      const productSnapshot = await getDocs(productCollection);
      const productList = productSnapshot.docs.map((doc) => doc.data());
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  const handleProductClick = (name) => {
    if (productName === name) {
      setQuantity((prev) => prev + 1); // Increment quantity if the same product is clicked
    } else {
      setProductName(name); // Set new product name
      setQuantity(1); // Reset quantity to 1 for the new product
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!productName) {
      alert("Please select a product.");
      return;
    }
    await addDoc(collection(db, "orders"), {
      productName,
      quantity,
      staffName,
      status: "Pending",
      timestamp: serverTimestamp(),
    });
    setProductName("");
    setQuantity(1);
    setStaffName("");
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-gray-100 shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Place Order</h2>

      {/* Display big buttons for each product */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        {products.map((product, index) => (
          <button
            key={index}
            onClick={() => handleProductClick(product.name)}
            className="bg-blue-500 text-white py-4 rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
          >
            {product.name}
          </button>
        ))}
      </div>

      {/* Order form */}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 rounded-lg shadow-md"
      >
        <label className="block mb-2">
          Selected Product:
          <input
            type="text"
            value={productName}
            readOnly
            className="block w-full p-2 border rounded bg-gray-200"
            placeholder="Click a product button"
          />
        </label>

        <label className="block mb-2">
          Quantity:
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => setQuantity((prev) => Math.max(1, prev - 1))} // Decrease, but not below 1
              className="bg-gray-300 text-black px-2 py-1 rounded-l-lg hover:bg-gray-400"
            >
              -
            </button>
            <input
              type="number"
              value={quantity}
              readOnly
              className="block w-16 text-center border-t border-b border-gray-300"
            />
            <button
              type="button"
              onClick={() => setQuantity((prev) => prev + 1)} // Increase quantity
              className="bg-gray-300 text-black px-2 py-1 rounded-r-lg hover:bg-gray-400"
            >
              +
            </button>
          </div>
        </label>

        <label className="block mb-2">
          Staff Name:
          <input
            type="text"
            value={staffName}
            onChange={(e) => setStaffName(e.target.value)}
            className="block w-full p-2 border rounded"
            required
          />
        </label>

        <button
          type="submit"
          className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600"
        >
          Place Order
        </button>
      </form>
    </div>
  );
};

export default PlaceOrder;
