// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEcDowMLmEaoit32q28dNkGO6PyKkAL18",
  authDomain: "ice-cream-yam-mochi.firebaseapp.com",
  projectId: "ice-cream-yam-mochi",
  storageBucket: "ice-cream-yam-mochi.firebasestorage.app",
  messagingSenderId: "725106482849",
  appId: "1:725106482849:web:b072f3809de1954583dae0",
  measurementId: "G-GB7DX98LQ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
export const db = getFirestore(app);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    console.error("Offline persistence failed: Multiple tabs open");
  } else if (err.code === "unimplemented") {
    console.error("Offline persistence is not supported by the browser");
  }
});

export const auth = getAuth(app);